<template>
    <div
        :id="title"
        class="bg-dark w-full px-120px py-80px flex gap-34px ipad:(px-24px py-48px items-center) mobile:(px-16px py-12px)"
    >
        <div
            class="ipad:(w-full flex flex-col items-center) mobile:(w-full flex flex-col items-center)"
        >
            <div
                class="section_headline text-light mobile:(leading-48px text-base-40px text-center)"
            >
                <span class="ligne">{{ title }}</span>
            </div>
            <div class="mt-24px">
                <div
                    class="flex flex-col text-light font-400 ipad:(items-center) mobile:(items-center)"
                >
                    <div
                        class="flex my-20px leading-28px items-center ipad:(flex-col w-574px text-center) mobile:(flex-col gap-8px text-center)"
                    >
                        <img class="w-32px h-32px mr-12px" src="../../../img/pin.svg" />
                        <p class="sf_18">{{ contacts.address }}</p>
                    </div>
                    <div
                        class="flex my-20px leading-28px text-base-18px items-center tracking-0.5px ipad:(flex-col w-574px text-center) mobile:(flex-col gap-8px text-center)"
                    >
                        <img class="w-32px h-32px mr-12px" src="../../../img/mail.svg" />
                        <p>
                            <a
                                class="link leading-24px font-400"
                                :href="filterDangerousHrefAndSrc(`mailto:${contacts.legalAddress}`)"
                            >
                                {{ contacts.mail }}
                            </a>
                        </p>
                    </div>
                    <div
                        class="flex my-20px leading-24px text-base-18px items-center tracking-0.5px ipad:(flex-col text-center) mobile:(flex-col gap-8px text-center)"
                    >
                        <img class="w-32px h-32px mr-12px" src="../../../img/enquiry.svg" />
                        <p>
                            <a
                                class="link leading-24px font-400"
                                :href="filterDangerousHrefAndSrc(`mailto:${contacts.legalAddress}`)"
                            >
                                {{ contacts.legalAddress }}
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { filterDangerousHrefAndSrc } from '@bit/utils'
export default {
    name: 'contacts',
    props: {
        contacts: {
            type: Object,
            default: () => {}
        },
        title: String
    },
    data() {
        return {
            expand: false
        }
    },
    methods: {
        filterDangerousHrefAndSrc
    }
}
</script>
<style></style>
